import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdCreditScore,
  MdHistoryEdu,
  MdSettings,
  MdOutlineWidgets,
  MdLock
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import PaymentMethod from "views/admin/paymentMethods";
import Logs from "views/admin/logs";
import Transactions from "views/admin/transactions";
import SignIn from "views/auth/signIn/index"

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    params: "",
    is_show: true,
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Transactions",
    layout: "/admin",
    path: "/transactions",
    params: "",
    is_show: true,
    icon: <Icon as={MdCreditScore} width='20px' height='20px' color='inherit' />,
    component: Transactions,
  },
  {
    name: "Payment Method",
    layout: "/admin",
    path: "/payment-method",
    params: "",
    is_show: true,
    icon: <Icon as={MdOutlineWidgets} width='20px' height='20px' color='inherit' />,
    component: PaymentMethod,
  },
  {
    name: "Settings",
    layout: "/admin",
    path: "/settings",
    params: "",
    is_show: false,
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: DataTables,
  },
  {
    name: "Logs",
    layout: "/admin",
    path: "/logs",
    params: "/:id?",
    is_show: true,
    icon: <Icon as={MdHistoryEdu} width='20px' height='20px' color='inherit' />,
    component: Logs,
  },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    params: "",
    is_show: false,
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
    component: SignIn,
  }
];

export default routes;
