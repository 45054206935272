export const formatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  maximumFractionDigits: 0
});

export const formatTime = (time) => {
  let t = new Date(new Date(time).getTime());
  let d = t.getDate();
  let dd = d < 10 ? `${d}`:d;
  let m = t.getMonth()+1;
  let mm = m < 10 ? `0${m}`:m;
  let h = t.getHours();
  let hh = h < 10 ? `0${h}`:h;
  let hm = t.getMinutes();
  let hmm = hm < 10 ? `0${hm}`:hm;
  let s = t.getSeconds();
  let ss = s < 10 ? `0${s}`:s;
  return {date:dd,month:mm,hours:hh,minuts:hmm,seconds:ss};
};