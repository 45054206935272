import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { formatter } from "variables/helpers";
import {
  Box,
  Icon,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import {MdBarChart} from "react-icons/md";
import { Chart as GoogleChart } from "react-google-charts";

const StyledChart = styled(GoogleChart)`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  border-color: white;
  border-style: solid; // Add this line
  border-width: 5px; // Add this line
  width: 100%;
`;

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isChartLoaded, setIsChartLoaded] = useState(false);
  const [isPieChartMonthLoaded, setIsPieChartMonthLoaded] = useState(false);
  const [isPieChartYearLoaded, setIsPieChartYearLoaded] = useState(false);
  
  const [trxSummary, setTrxSummary] = useState(null);
  const [columnChart, setColumnChart] = useState(null);
  const [pieChartYear, setPieChartYear] = useState(null);
  const [pieChartMonth, setPieChartMonth] = useState(null);

  const optionsPieChartMonth = {
    title: "Transaction this Month",
  };

  const optionsPieChartYear = {
    title: "Transaction this Year",
  };

  useEffect(() => {
    fetch('https://proxy.parkingplus.id/v1/transactions/summary')
      .then(res => res.json())
      .then((result)=>{
        setIsLoaded(true);
        setTrxSummary(result);
      },(error) => {
        setIsLoaded(true);
        setError(error);
      })

    fetch('https://proxy.parkingplus.id/v1/dashboard/column-chart')
      .then(res => res.json())
      .then((result)=>{
        setIsChartLoaded(true);
        setColumnChart(result);
      },(error) => {
        setIsChartLoaded(true);
        setError(error);
      })

    fetch('https://proxy.parkingplus.id/v1/dashboard/pie-chart-year')
      .then(res => res.json())
      .then((result)=>{
        setIsPieChartYearLoaded(true);
        setPieChartYear(result);
      },(error) => {
        setIsPieChartYearLoaded(true);
        setError(error);
      })

    fetch('https://proxy.parkingplus.id/v1/dashboard/pie-chart-month')
      .then(res => res.json())
      .then((result)=>{
        setIsPieChartMonthLoaded(true);
        setPieChartMonth(result);
      },(error) => {
        setIsPieChartMonthLoaded(true);
        setError(error);
      })

  },[])

  if (error) return <div>Error: {error.message}</div>;
  if (!isLoaded || !trxSummary) return <div>Loading...</div>;
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {!isChartLoaded && <div>Loading...</div>}
      {isChartLoaded && (
        <StyledChart
          chartType="ColumnChart"
          width="100%"
          height="400px"
          data={columnChart}
        />
      )}
      <SimpleGrid columns={{ base: 1, md: 2, lg: 2, "2xl": 4 }} mt='20px' gap='20px'>
        {!isPieChartYearLoaded && (
          <div>Loading...</div>
        )}
        {isPieChartYearLoaded && (
        <Box borderRadius='20px' bg='white' p='6px'>
          <GoogleChart
          chartType="PieChart"
          data={pieChartYear}
          options={optionsPieChartYear}
          width={"100%"}
          height={"auto"}
          />
        </Box>
        )}
        {!isPieChartMonthLoaded && (
          <div>Loading...</div>
        )}
        {isPieChartMonthLoaded && (
        <Box borderRadius='20px' bg='white' p='6px'>
          <GoogleChart
          chartType="PieChart"
          data={pieChartMonth}
          options={optionsPieChartMonth}
          width={"100%"}
          height={"auto"}
          />
        </Box>
        )}
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'
        mt='20px'>
          {trxSummary.map((e,i)=>{
            return (
              <MiniStatistics
                startContent={
                  <IconBox
                    w='56px'
                    h='56px'
                    bg={boxBg}
                    icon={
                      <Icon w='32px' h='32px' as={MdBarChart} color={brandColor} />
                    }
                  />
                }
                name={e.channel}
                value={formatter.format(e.total)}
                key={i}
              />
            )
          })}
      </SimpleGrid>
    </Box>
  );
}
