import React ,{useState,useEffect,useMemo} from "react";
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import {
  Link,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";
export function SearchTransactions(props) {
  // Pass the computed styles into the `__css` prop
  const { variant, background, children, placeholder, borderRadius, ...rest } =
    props;
  const location = useLocation();
  const queryParams = useMemo(()=>new URLSearchParams(location.search),[location.search]);
  // Chakra Color Mode
  const searchIconColor = useColorModeValue("gray.700", "white");
  const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
  const inputText = useColorModeValue("gray.700", "gray.100");
  
  const [vSearch,setSearch] = useState('');
  useEffect(()=>{
    if(queryParams.get('search')) setSearch(queryParams.get('search'));
  },[queryParams])
  
  const path = ()=>{
    let hash = window.location.hash;
    hash = hash.slice(1,hash.length)
    if(!vSearch) {
      hash = hash.replace(/(\?|&)search=[^&]*/, "");
      let regex = /[&]/;
      if(regex.test(hash)) return hash.replace(regex,'?')
      return hash;
    }
    if(queryParams.size > 0) {
      let regex = /[?&]search=/;
      if(regex.test(hash)) return hash.replace(/(\?|&)search=[^&]*/, "$1search=" + vSearch);
      return `${hash}&search=${vSearch}`;
    }
    return 'transactions?search='+vSearch;
  }
  return (
    <InputGroup w={{ base: "100%", md: "200px" }} {...rest}>
      <InputLeftElement
        children={
          <Link as={ReactRouterLink} to={path()}>
            <IconButton
              bg='inherit'
              borderRadius='inherit'
              _hover='none'
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
              icon={
                <SearchIcon color={searchIconColor} w='15px' h='15px' />
              }></IconButton>
          </Link>
        }
      />
      <Input
        variant='search'
        fontSize='sm'
        bg={background ? background : inputBg}
        color={inputText}
        fontWeight='500'
        _placeholder={{ color: "gray.400", fontSize: "14px" }}
        borderRadius={borderRadius ? borderRadius : "30px"}
        placeholder={placeholder ? placeholder : "Search..."}
        onChange={(e)=>setSearch(e.target.value)}
        value={vSearch}
      />
    </InputGroup>
  );
}
