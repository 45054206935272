import { formatter } from "variables/helpers";
import { Link as ReactRouterLink, useLocation } from 'react-router-dom'
import {
  Link as ChakraLink,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Progress,
  IconButton
} from "@chakra-ui/react";
import {BsLink45Deg} from "react-icons/bs";
import React, {useMemo} from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
// Custom components
import Card from "components/card/Card";

export default function TransactionsTable(props) {
  const { columnsData, tableData, totalItems } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // useMemo is Hook that cache calculation between re-renders
  // const logs = useMemo(() => datas, [datas]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
// console.log(tableData)
  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = totalItems;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textRed = useColorModeValue("red", "red");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const path = (value)=>{
    let hash = window.location.hash;
    hash = hash.slice(1,hash.length)
    if(queryParams.size > 0) {
      let regex = /[?&]idc=/;
      if(regex.test(hash)) return hash.replace(/(\?|&)idc=[^&]*/, "$1idc=" + value);
      return `${hash}&idc=${value}`;
    }
    return 'transactions?idc='+value;
  }
  return (
    <>
      <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                <Th
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    NO
                  </Flex>
                </Th>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);

              let bgTr = '#fff2df';
              if(row.values.status === 'capture') bgTr = '#c6f68d';
              if(row.values.status === 'pending') bgTr = '#f2fde4';
              let idc = row.original.idc;
              return (
                <Tr {...row.getRowProps()} key={index} style={{background:bgTr}}>
                  <Td
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    {index+1}
                  </Td>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Order Id") {
                      data = (
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "Name") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700' _hover={{color:'red'}}>
                          <ChakraLink as={ReactRouterLink} to={path(idc)}>
                            {cell.value}
                          </ChakraLink>
                        </Text>
                      );
                    }else if (cell.column.Header === "Amount") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {formatter.format(cell.value)}
                        </Text>
                      );
                    } else if (cell.column.Header === "Status") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Progress") {
                      data = (
                        <Flex align='center'>
                          <Progress
                            hasStripe
                            isAnimated
                            variant='table'
                            colorScheme='brandScheme'
                            h='8px'
                            w='70px'
                            value={cell.value}
                          />
                          <Text color={textRed} fontSize='xs' fontWeight='100'>
                            {cell.value}%
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "Payment") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Date") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          <ChakraLink as={ReactRouterLink} to={'/admin/logs/'+cell.value}>
                            <IconButton
                              variant="outline"
                              colorScheme="orange"
                              aria-label="Goto Logs"
                              borderRadius="5px"
                              icon={<BsLink45Deg />}
                            />
                          </ChakraLink>
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    </>
  );
}
