import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
} from "@chakra-ui/react";
// Custom components
import PaymentMethod from "./components/PaymentMethod";
import InactivePaymentMethod from "./components/InactivePaymentMethod";

export default function ShowPaymentMethod() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [paymentMethod, setPaymentMethods] = useState(null);

  useEffect(() => {
    fetch('https://proxy.parkingplus.id/v1/payment/method')
      .then(res => res.json())
      .then((result)=>{
        setIsLoaded(true);
        setPaymentMethods(result);
      },(error) => {
        setIsLoaded(true);
        setError(error);
      })
  },[])

  if (error) return <div>Error: {error.message}</div>;
  if (!isLoaded || !paymentMethod) return <div>Loading...</div>;
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
        <PaymentMethod data={paymentMethod}/>
        <InactivePaymentMethod data={paymentMethod}/>
      </SimpleGrid>
    </Box>
  );
}
