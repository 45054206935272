import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  // ModalFooter,
  ModalBody,
  ModalCloseButton,
  // Button,
  useDisclosure,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  IconButton
} from "@chakra-ui/react";
import React, {useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { SearchIcon } from '@chakra-ui/icons'
// Custom components
import Card from "components/card/Card";

export default function LogsTable(props) {
  const { columnsData, tableData, datas } = props;
  const [getId, setId] = useState(null);
  // useMemo is Hook that cache calculation between re-renders
  const logs = useMemo(() => datas, [datas]);
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 15;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const { isOpen, onOpen, onClose } = useDisclosure()
  let details = JSON.stringify({code:400})// hanya utk default
  logs.map((e)=>{
    if(e.id === getId) return details = e.content
    return null;// default wajib ada return d semua data yg d loop
  })
  // useEffect(()=>{
  // },[getId])
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} scrollBehavior={'inside'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Logs Content : {getId}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <textarea rows="50" cols="64" readOnly value={JSON.stringify(JSON.parse(details),null,4)}></textarea>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Card direction='column' w='100%' px='0px' overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Table {...getTableProps()} variant='striped'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                <Th
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    NO
                  </Flex>
                </Th>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index} style={{background:'#f2fde4'}}>
                  <Td
                    key={index}
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    {index+1}
                  </Td>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "Id") {
                      data = (
                        <Flex align='center'>
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                    } else if (cell.column.Header === "Order Id") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }else if (cell.column.Header === "Action") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Date") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          <IconButton
                            variant="outline"
                            colorScheme="orange"
                            aria-label="Look details"
                            borderRadius="5px"
                            icon={<SearchIcon />}
                            onClick={()=>{onOpen();setId(cell.value)}}
                          />
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    </>
  );
}
