import { useState, useEffect, React } from 'react';
import { Box, SimpleGrid, Stack, Skeleton } from "@chakra-ui/react";
import TransactionsTable from "./components/TransactionsTable";
import {TransactionsColumns} from "./variables/TransactionsColumns";
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

export default function Transactions() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // let path = window.location.hash;
  const sts = queryParams.get('sts');
  const idc = queryParams.get('idc');
  const search = queryParams.get('search');

  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const [totalTransaction, setTotalTransaction] = useState(0);
  // pagination
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 20;
  // const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(totalTransaction / itemsPerPage);
  // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % totalTransaction;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const join = (url)=>{
      let tanya = /[?]/;
      let join = tanya.test(url) ? '&':'?';
      return url+join;
    }
    const path = ()=>{
      let url = 'https://proxy.parkingplus.id/v1/transactions/page/'+itemOffset+'/'+itemsPerPage
      if(queryParams.size > 0){
        if(sts) url = join(url)+'sts='+sts
        if(idc) url = join(url)+'idc='+idc
        if(search) url = join(url)+'s='+search.toLowerCase()
      }
      return url;
    }
    fetch(path())
      .then(res => res.json())
      .then(async (result)=>{
        // console.log(result)
        setTotalTransaction(result.count)
        let tmp = []
        // get log to prommise all
        let logProgress = '';
        const tmpPromises = result.transactions.map((e) =>
          Logs(e.transaction_id, e.payment_channel)
        );
        await Promise.all(tmpPromises)
        .then((logs) => {
          // console.log(logs);
          logProgress = logs
        })
        .catch((error) => {
          console.error('Error fetching logs:', error);
        });
        // console.log(logProgress)
        // loop progress n arrange to trx
        logProgress.forEach(el => {
          // loop trx n matching data trx to log
          result.transactions.forEach(e => {
            // skip loop trx if data not match with log to speed the next process
            // actually it i'll  match but for avoid if any data no matching
            if(el.id !== e.transaction_id) return;

            const t = new Date(e.createdAt);
            const dd = String(t.getDate()).padStart(2, '0');
            const mm = String(t.getMonth() + 1).padStart(2, '0');
            const hh = String(t.getHours()).padStart(2, '0');
            const hm = String(t.getMinutes()).padStart(2, '0');
            const ss = String(t.getSeconds()).padStart(2, '0');
            tmp.push({
              orderid:e.transaction_id,
              name:e.customer.f_name+' '+e.customer.l_name,
              amount:e.amount,
              status:e.transaction_status,
              payment:e.payment_channel,
              date:`${dd}/${mm}/${t.getFullYear()} ${hh}:${hm}:${ss}`,
              detail:e.transaction_id,
              progress:el.progress,
              idc:e.customer_id
            })
          });
        })

        setIsLoaded(true);
        setTransactions(tmp);
      },(error) => {
        setIsLoaded(true);
        setError(error);
      }
    )
  },[itemOffset,itemsPerPage,sts,idc,search,queryParams.size])

  if (error) return <div>Error: {error.message}</div>;
  if (!isLoaded || !transactions) {
    const LoopSkeleton = ()=>{
      let res = []
      for(let i = 0; i < 10; i++){
        res.push(<Skeleton height='40px' key={i} />)
      }
      return res;
    }
    return (
      <Stack>
        <LoopSkeleton />
      </Stack>
    );
  }
  
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>

        <TransactionsTable
          columnsData={TransactionsColumns}
          tableData={transactions}
          totalItems={itemsPerPage}
        />
        <MyPaginate 
          breakLabel="..."
          previousLabel="prev"
          nextLabel="next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          renderOnZeroPageCount={null}
        />
      </SimpleGrid>
    </Box>
  );
}

const Logs = async (req,channel)=>{
  try {
    return fetch('https://proxy.parkingplus.id/v1/logs?s='+req)
    .then(rest => rest.json())
    .then((result)=>{
      let res = 0;
      let ovoAction = ['setup','response setup','select payment method','request emoney ovo','doku notify','doku finish ovo','sent notify to tridens','charge','delete_credit_card']
      let linkajaAction = ['setup','response setup','select payment method','request emoney linkaja','received emoney response linkaja','doku notify','doku finish linkaja','sent notify to tridens','charge','delete_credit_card']
      let gopayAction = ['setup','response setup','select payment method','request emoney gopay','received emoney response gopay','midtrans notify','midtrans finish gopay','sent notify to tridens','charge','delete_credit_card']
      let shopeepayAction = ['setup','response setup','select payment method','request emoney shopeepay','received emoney response shopeepay','midtrans notify','midtrans finish shopeepay','sent notify to tridens','charge','delete_credit_card']
      let bcaAction = ['setup','response setup','select payment method','request bank transfer','received bank transfer response','midtrans notify','midtrans finish','sent notify to tridens','charge','delete_credit_card']
      let bniAction = ['setup','response setup','select payment method','request bank transfer','received bank transfer response','midtrans notify','midtrans finish','sent notify to tridens','charge','delete_credit_card']
      let briAction = ['setup','response setup','select payment method','request bank transfer','received bank transfer response','midtrans notify','midtrans finish','sent notify to tridens','charge','delete_credit_card']
      let mandiriAction = ['setup','response setup','select payment method','request bank transfer','received bank transfer response','midtrans notify','midtrans finish','sent notify to tridens','charge','delete_credit_card']
      let octoAction = ['setup','response setup','select payment method','request direct debit','received direct debit response','midtrans notify','midtrans finish redirect','midtrans finish octo','sent notify to tridens','charge','delete_credit_card']
      let ccAction = ['setup','response setup','select payment method','request emoney creditcard','received emoney response creditcard','doku notify','doku finish creditcard','sent notify to tridens','charge','delete_credit_card']
      let isExist = []
      switch(channel) {
        case 'ovo':
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(ovoAction.includes(e.action)) {
              isExist.push(e.action)
              res += e.action === 'charge' ? 20:10;
            }
          })
          break;
        case 'linkaja':
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(linkajaAction.includes(e.action)) {
              isExist.push(e.action)
              res += 10;
            }
          })
          break;
        case 'gopay':
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(gopayAction.includes(e.action)) {
              isExist.push(e.action)
              res += 10;
            }
          })
          break;
        case 'shopeepay': // VD65PL16GLR628
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(shopeepayAction.includes(e.action)) {
              isExist.push(e.action)
              res += 10;
            }
          })
          break;
        case 'bca':
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(bcaAction.includes(e.action)) {
              isExist.push(e.action)
              res += 10;
            }
          })
          break;
        case 'bni':
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(bniAction.includes(e.action)) {
              isExist.push(e.action)
              res += 10;
            }
          })
          break;
        case 'bri':
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(briAction.includes(e.action)) {
              isExist.push(e.action)
              res += 10;
            }
          })
          break;
        case 'mandiri':
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(mandiriAction.includes(e.action)) {
              isExist.push(e.action)
              res += 10;
            }
          })
          break;
        case 'octo':
          result.forEach(e => {
            if(octoAction.includes(e.action)) {
              isExist.push(e.action)
              res += ['midtrans finish redirect','midtrans finish octo'].includes(e.action) ? 5:10;
            }
          })
          break;
        case 'creditcard':
          result.forEach(e => {
            if(isExist.includes(e.action)) return;
            if(ccAction.includes(e.action)) {
              isExist.push(e.action)
              res += 10;
            }
          })
          break;
        default:
          res  = 0;
      }
      return {id:req,progress:res};
    });
  } catch (error) {
    return false;
  }
}

const MyPaginate = styled(ReactPaginate)`
  margin-bottom: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0 5rem;

  li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
  }
  li.previous,li.next {
    border-radius: 7px;
    border: gray 1px solid;
    cursor: pointer;
    margin-top: -1px;
  }
  li.previous a,
  li.next a,
  li.break a {
    border-color: transparent;
  }
  li.selected a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
  }
  li.disabled a {
    color: grey;
  }
  li.disable,
  li.disabled a {
    cursor: default;
  }
`;
