import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import 'assets/css/App.css';
import { HashRouter} from 'react-router-dom';

import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { AuthContextProvider } from 'contexts/AuthContext';
import IsAuth from 'contexts/IsAuth';

const App =()=>{
    return (
	<ChakraProvider theme={theme}>
        <React.StrictMode>
            <ThemeEditorProvider>
                <HashRouter>
					<AuthContextProvider>
						<IsAuth />
					</AuthContextProvider>
                </HashRouter>
            </ThemeEditorProvider>
        </React.StrictMode>
    </ChakraProvider>
	)
}
ReactDOM.render(<App />,document.getElementById('root'));
