export const TransactionsColumns = [
  {
    Header: "Order Id",
    accessor: "orderid",
  },
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Progress",
    accessor: "progress",
  },
  {
    Header: "Payment",
    accessor: "payment",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "",
    accessor: "detail",
  },
];