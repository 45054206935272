export const LogsColumns = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "Order Id",
    accessor: "orderid",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "",
    accessor: "detail",
  },
];