// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTIc1-HyB-lD2Wq9V2qfeXVYqDeP9KibE",
  authDomain: "parking-plus-82324.firebaseapp.com",
  projectId: "parking-plus-82324",
  storageBucket: "parking-plus-82324.appspot.com",
  messagingSenderId: "829568984369",
  appId: "1:829568984369:web:b710c0bebbf28885f43c10",
  measurementId: "G-CPYMPYYEEJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app)