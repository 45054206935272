// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  SimpleGrid,
  Image
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import React from "react";
import { MdOutlineWidgets, } from "react-icons/md";

export default function PayamentMethod(props) {
  const { data,...rest } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  // Chakra Color Mode
  const miniBg = useColorModeValue("secondaryGray.100", "whiteAlpha.100");
  return (
    <Card align='center' direction='column' w='100%' {...rest}>
      <Flex align='center' w='100%' px='15px' py='10px'>
        <Text
          me='auto'
          color={textColor}
          fontSize='xl'
          fontWeight='700'
          lineHeight='100%'>
          Active payment methods
        </Text>
        <Button
          align='center'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w='37px'
          h='37px'
          lineHeight='100%'
          borderRadius='10px'
          >
          <Icon as={MdOutlineWidgets} color={iconColor} w='24px' h='24px' />
        </Button>
      </Flex>

      <Box h='auto' mt='auto'>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
          gap='20px'
          mb='20px'>
            {data.map((e,i)=>{
              if(e.status !== 1) return null;
              return (
                <MiniStatistics
                  startContent={
                    <Image
                      src={'https://parkingplus-s3.sgp1.cdn.digitaloceanspaces.com/logo_pm/'+e.logo}
                      alt={e.name}
                    />
                  }
                  // name={e.name}
                  // value={e.name}
                  bg={miniBg}
                  key={i}
                />
              )
            })}
        </SimpleGrid>
      </Box>
    </Card>
  );
}
