import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { Box, SimpleGrid } from "@chakra-ui/react";
import LogsTable from "./components/LogsTable";
import {LogsColumns} from "./variables/LogsColumns";

const Logs = () => {
  const { id } = useParams();
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [logs, setLogs] = useState(null);
  const [datas, setDatas] = useState(null);
  
  useEffect(() => {
    let url = '/15';
    if(id !== undefined) url = '?s='+id;

    fetch('https://proxy.parkingplus.id/v1/logs'+url)
      .then(res => res.json())
      .then((result)=>{
        let tmp = []
        setDatas(result)

        result.forEach(e => {
          let header = JSON.parse(e.header)
          // let content = JSON.parse(e.content)
          // tmpDatas.push(e)

          let t = new Date(new Date(e.createdAt).getTime())
          let d = t.getDate()
          let dd = d < 10 ? `${d}`:d
          let m = t.getMonth()+1
          let mm = m < 10 ? `0${m}`:m
          let h = t.getHours()
          let hh = h < 10 ? `0${h}`:h
          let hm = t.getMinutes()
          let hmm = hm < 10 ? `0${hm}`:hm
          let s = t.getSeconds()
          let ss = s < 10 ? `0${s}`:s
          tmp.push({
            id:e.id,
            orderid:header.transaction_id,
            action:e.action,
            date:`${dd}/${mm}/${t.getFullYear()} ${hh}:${hmm}:${ss}`,
            detail:e.id
          })
        });
        // console.log(result)
        setIsLoaded(true);
        setLogs(tmp);
      },(error) => {
        setIsLoaded(true);
        setError(error);
      })
  },[id])

  if (error) return <div>Error: {error.message}</div>;
  if (!isLoaded || !logs) return <div>Loading...</div>;
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        spacing={{ base: "20px", xl: "20px" }}>

        <LogsTable
          columnsData={LogsColumns}
          tableData={logs}
          datas={datas}
        />

      </SimpleGrid>
    </Box>
  );
}

export default Logs;