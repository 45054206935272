import { useContext, createContext, useEffect, useState } from "react";
import { GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { auth } from "../firebase";

const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [user,setuser] = useState({})
  const googleSignIn = ()=>{
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
  }
  const emailSignIn = async (email,password)=>{
    try {
      await signInWithEmailAndPassword(auth,email,password)
    } catch (error) {
      console.log(error)
      alert('Ups sorry, make sure you enter email and password correctly')
    }
  }

  const logOut = ()=>{
    signOut(auth)
    setuser({})
  }

  useEffect(()=>{
    const unsubscribe = onAuthStateChanged(auth,(currentUser)=>{
      console.log(currentUser)
      setuser(currentUser)
    })
    
    return ()=>{
      unsubscribe()
    }
  },[])
  return (
    <AuthContext.Provider value={{googleSignIn, emailSignIn, logOut, user}}>
      {children}
    </AuthContext.Provider>
  )
}

export const UserAuth = ()=>{
  return useContext(AuthContext)
}