import React from "react";
import { UserAuth } from "./AuthContext";
import { Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';

const IsAuth = ()=>{
    const {user} = UserAuth()
    if(user) {
        // console.log('loged')
        return (
        <Switch>
            <Route path={`/admin`} component={AdminLayout} />
            <Redirect from='/' to='/admin' />
        </Switch>);
    }else{
        // console.log('logout')
        return (
        <Switch>
            <Route path={`/auth`} component={AuthLayout} />
            <Redirect from='/' to='/auth' />
        </Switch>
        );
    }
    
}
export default IsAuth;